<template>
  <section>
    <recovery-password-form />
  </section>
</template>

<script>
export default {
  name: 'RecoveryPassword',
  components: {
    RecoveryPasswordForm: () => import('@/components/Access/RecoveryPasswordForm.vue')
  }
}
</script>

<style lang="scss" scoped>

</style>
